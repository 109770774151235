body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background: radial-gradient(circle at top right, #f39b51, #de3f81, #7f28c4);
}

.App {
  background: radial-gradient(circle at top right, #f39b51, #de3f81, #7f28c4);
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  font-style: normal;
  opacity: 1;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  min-height: 100vh;
}

.upside {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  max-width: 100%;
  height: auto;
  flex-direction: row;
  display: flex;
  padding: 0.25rem;
  vertical-align: middle;
  align-items: center;
  justify-content: space-around;
}

.logo {
  width: 40%;
}

.logo img {
  padding-top: .25rem;
  padding-bottom: .25rem;
  width: 75%;
  max-width: 75%;
  height: auto;
  vertical-align: middle;
}

.language {
  color: white;
  display: flex;
  align-items: center;
  border: 1px solid #000;
  border-radius: 9999px;
  transition: all 0.5s ease;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.8);
  background: linear-gradient(to right, #D86F71 50%, #AA4098 50%);
  vertical-align: middle;
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  margin: 0;
}

.language p {
  margin: 0;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  cursor: pointer;
}

.language:hover {
  border-color: #AA4098;
}

.small-text {
  margin-block-end: 0rem;
  text-align: center;
}

.title {
  display: flex;
  align-items: center;
  top: calc(15% + 0px);
  height: auto;
  text-align: center;
  font-size: 18px;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.title h1 {
  margin: auto;
  color: white;
  line-height: 1.5rem;
  padding: 0;
  z-index: 10;
}

.photo {
  width: 75%;
  max-width: 65%;
  height: auto;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

.photo img {
  max-width: 75%;
  height: auto;
}

.content {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: .25px solid #fff;
  border-color: hsla(0, 0%, 100%, .2);
  height: auto;
  border-radius: 1rem;
  padding: 0.75rem;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.8);
  background-color: transparent;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  margin: 0;
  max-width: 75%;
}

.input {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.lighting-vibration-button {
  width: 100%;
}

.input p {
  margin: 0;
}

.textfield {
  display: flex;
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.cc {
  font-weight: 800;
  color: rgba(71, 85, 105, 1);
  padding: .5rem;
  background-color: white;
  border-color: #000;
  border-width: 1px;
  border-top-left-radius: .75rem;
  border-bottom-left-radius: .75rem;
  display: block;
  text-align: center;
  justify-content: center;
  justify-items: center;
  margin: auto;
  line-height: 1.25rem;
}

.textfield input {
  font-size: .875rem;
  line-height: 1.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  color: #AA4098;
  padding: 0.5rem 1.5rem;
  background-color: white;
  border-color: #000;
  border-width: 1px;
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
  width: 75%;
  display: block;
}

.textfield input:focus {
  border-color: #AA4098;
  border-width: 2px;
  outline: none;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: .75rem;
  gap: 1rem;
}

.content button,
#exit {
  padding: .5rem;
  background-color: #7f28c4;
  border-radius: .25rem;
  cursor: pointer;
  font-size: 100%;
  color: white;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  outline: none;
  border: none;
  text-transform: none;
  border: 1px solid #7f28c4;
  width: auto;
  min-width: 120px;
}

.content button:hover,
#exit:hover {
  border-width: 2.5px;
  border-color: white;
}

#exit {
  margin-top: 0;
}

.content a {
  margin-bottom: .75rem;
  transition: .3s;
  cursor: pointer;
  font-size: 100%;
  color: white;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  outline: none;
  border: none;
  text-transform: none;
  text-align: center;
}

.terms {
  margin-top: .75rem;
  margin-bottom: .75rem;
  transition: .3s;
  padding: 0.25rem 0rem;
  cursor: pointer;
  font-size: 85%;
  align-items: flex-start;
  word-spacing: normal;
  letter-spacing: normal;
  color: white;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  background-image: none;
  text-transform: none;
  text-align: center;
  margin-block: 0rem;
}

.terms a {
  text-decoration: underline;
  color: white;
  margin-left: 0.5rem;
}

.terms ul ,
.terms ol {
  margin-block: 0rem;
  text-align: justify;
  font-size: 9px;
  line-height: 0.9rem;
  padding-inline: 0rem;
}

.terms p {
  line-height: 1rem;
  font-size: 10px;
  margin-block: 0;
}

.terms ul span,
.terms ol span{
  margin-block: 0rem;
  text-align: justify;
  font-size: 9px;
  line-height: 0.9rem;
  padding-inline: 0rem;
}

.arabic {
  direction: rtl;
}

.english {
  direction: ltr;
}

.popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 1;
  background: radial-gradient(circle at bottom right, #f39b51, #de3f81, #7f28c4);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.8);
  min-width: 20%;
  height: auto;
}

.popup-content {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.close:hover {
  color: #AA4098;
}

.otp-input-container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 5%;
}

.otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 5px;
  outline: none;
  font-weight: bold;
}

.otp-input:focus {
  border-color: #AA4098;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
}

.verify button {
  margin-top: .5rem;
  margin-bottom: .75rem;
  transition: .3s;
  padding: .4rem;
  background-color: #AA4098;
  border-radius: .25rem;
  cursor: pointer;
  font-size: 100%;
  text-align: center;
  align-items: flex-start;
  word-spacing: normal;
  letter-spacing: normal;
  word-spacing: normal;
  color: white;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  outline: none;
  border: none;
  background-image: none;
  text-transform: none;
  text-align: center;
  border: 1px solid #AA4098;
}

.verify button:hover {
  border-width: 2.5px;
  border-color: white;
}

.pricing-box {
  width: 90px;
  height: auto;
  border: 2px solid #ddd;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  opacity: 0.7;
  background-color: #7f28c4;
  border: 1px solid #7f28c4;
  cursor: pointer;
}


.pricing-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
}

.pricing-box.selected {
  opacity: 1;
  border: 1px solid #fff;
}

.english,
.arabic {
  margin-block-start: 0;
}

@media screen and (min-width: 1025px) {
  .title h1 {
    font-size: 21px;
    line-height: 2rem;
    margin-inline: 0rem;
  }

  .photo {
    max-width: 500px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 651px) {
  .title h1 {
    font-size: 19px;
    line-height: 2rem;
  }

  .photo {
    max-width: 80%;
  }
}

@media screen and (max-width: 650px) and (min-width: 375px) {
  .title h1 {
    font-size: 13.5px;
    line-height: 1.75rem;
    transition: all 0.3s ease-in-out;
  }

  .content {
    width: 87%;
    padding: 1rem;
  }

  .button-container {
    flex-direction: row;
    gap: 0.5rem;
  }

  .photo {
    max-width: 75%;
    margin-top: 0;
  }

  .small-text {
    margin-block: 0.1rem;
    font-size: 11px;
  }

  .title {
    padding: 0.5rem;
  }

  .pricing-box {
    font-size: 10px;
    padding: 6px;
  }

  .content button,
  #exit {
    font-size: 11px;
    padding: 0.3rem;
    min-width: 105px;
  }
}

@media screen and (max-width: 374px) {
  .title h1 {
    font-size: 11px;
    line-height: 0.75rem;
    padding: 0.5rem;
  }

  .title {
    padding: 0;
  }

  .logo {
    width: 45%;
  }

  .language {
    font-size: 12px;
  }

  .language p {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .content {
    width: 80%;
    padding: 1rem;
    font-size: 13.25px;
  }

  .photo {
    max-width: 300px;
    width: unset;
    margin-inline: 0;
    padding: 0.5rem;
  }

  .small-text {
    font-size: 9.5px;
  }

  .pricing-box {
    font-size: 10px;
    padding: 6px;
  }

  .content button,
  #exit {
    font-size: 11px;
    padding: 0.3rem;
    min-width: 105px;
  }
}